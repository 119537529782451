<script lang="ts">
  import {
    exercicesParams,
    darkMode,
    globalOptions
  } from '../../../lib/stores/generalStore'
  import {
    mathaleaGenerateSeed,
    mathaleaUpdateUrlFromExercicesParams
  } from '../../../lib/mathalea.js'
  import Footer from '../../Footer.svelte'
  import NavBar from '../../shared/header/NavBar.svelte'
  import Button from '../../shared/forms/Button.svelte'
  import FormRadio from '../../shared/forms/FormRadio.svelte'
  import { onMount } from 'svelte'
  import ButtonToggleAlt from '../../shared/forms/ButtonToggleAlt.svelte'
  import ModalActionWithDialog from '../../shared/modal/ModalActionWithDialog.svelte'
  import ModalForQRCode from '../../shared/modal/ModalForQRCode.svelte'
  import {
    copyLinkToClipboard,
    copyEmbeddedCodeToClipboard
  } from '../../../lib/components/clipboard'
  import { buildUrlAddendumForEsParam } from '../../../lib/components/urls'
  import type { NumericRange } from '../../../lib/types'
import displayKeyboardToggle from '../../../lib/displayKeyboardToggle'

  onMount(() => {
    // mathaleaUpdateUrlFromExercicesParams($exercicesParams)
    handleSeed()
  })

  const formatQRCodeIndex: NumericRange<0, 2> = 0
  const QRCodeWidth = 100

  const availableLinkFormats = {
    clear: {
      toolTipsMessage: 'Plain link',
      icon: 'bx-glasses-alt',
      isShort: false,
      isEncrypted: false
    },
    short: {
      toolTipsMessage: 'Shortcut link',
      icon: 'bx-move-horizontal',
      isShort: true,
      isEncrypted: false
    },
    crypt: {
      toolTipsMessage: 'Encrypted link',
      icon: 'bx-lock',
      isShort: false,
      isEncrypted: true
    }
  }

  type LinkFormat = keyof typeof availableLinkFormats
  let currentLinkFormat: LinkFormat = 'clear'

  function handleEleveVueSetUp () {
    let url = document.URL + '&v=eleve'
    url += '&title=' + $globalOptions.title
    url += '&es=' + buildUrlAddendumForEsParam()
if ($globalOptions.beta) url += '&beta=1'
    window.open(url, '_blank')?.focus()
  }

  // Gestion du clavier
  let isBetaKeyboard: boolean = $globalOptions.beta ?? false
  function handleKeyboard () {
    $globalOptions.beta = isBetaKeyboard
    displayKeyboardToggle(isBetaKeyboard)
  }

  // Gestion de la graine
  let isDataRandom: boolean = false
  function handleSeed () {
    for (const param of $exercicesParams) {
      if (!isDataRandom && param.alea === undefined) {
        param.alea = mathaleaGenerateSeed()
      } else {
        param.alea = undefined
      }
    }
    mathaleaUpdateUrlFromExercicesParams($exercicesParams)
  }
</script>

<main
  class="mb-auto flex flex-col min-h-screen justify-between bg-coopmaths-canvas dark:bg-coopmathsdark-canvas {$darkMode.isActive
    ? 'dark'
    : ''}"
>
  <NavBar subtitle="Worksheets for Students" subtitleType="export" />
  <div
    class="flex flex-col h-full w-full bg-coopmaths-canvas dark:bg-coopmathsdark-canvas"
  >
    <div
      class="h-full w-full md:w-2/3 lg:w-3/5 flex flex-col p-4 md:py-10 bg-coopmaths-canvas dark:bg-coopmathsdark-canvas mx-auto"
    >
      <div
        class="flex flex-col md:flex-row justify-start px-4 py-2 bg-coopmaths-canvas dark:bg-coopmathsdark-canvas"
      >
        <h3
          class="font-bold text-2xl text-coopmaths-struct dark:text-coopmathsdark-struct"
        >
          Settings
        </h3>
      </div>
      <div class="pt-2 pl-2 grid grid-flow-row md:grid-cols-2 gap-4">
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-coopmaths-struct-light dark:text-coopmathsdark-struct-light"
          >
            Title
          </div>
          <div class="pl-4 flex flex-col">
            <input
              type="text"
              id="config-eleve-titre-input"
              class="w-1/2 text-sm bg-coopmaths-canvas dark:bg-coopmathsdark-canvas text-coopmaths-corpus dark:text-coopmathsdark-corpus border border-coopmaths-action dark:border-coopmathsdark-action font-light focus:border focus:border-coopmaths-action dark:focus:border-coopmathsdark-action focus:outline-0 focus:ring-0"
              bind:value={$globalOptions.title}
            />
            <div
              class="mt-1 text-coopmaths-corpus font-light italic text-xs {$globalOptions.title &&
              $globalOptions.title.length === 0
                ? ''
                : 'invisible'}"
            >
              Pas de bandeau si laissé vide.
            </div>
          </div>
        </div>
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-coopmaths-struct-light dark:text-coopmathsdark-struct-light"
          >
            View Style
          </div>
          <FormRadio
            title="présentation"
            bind:valueSelected={$globalOptions.presMode}
            labelsValues={[
              { 
                label: 'All exercises on one page', 
                value: 'liste_exos'
              },
              {
                label: 'One page per exercise',
                value: 'un_exo_par_page',
                isDisabled: $exercicesParams.length === 1
              },
              {
                label: 'All questions on one page',
                value: 'liste_questions'
              },
              { label: 'One page per question', value: 'une_question_par_page' }
              // { label: 'Cartes', value: 'cartes' }
            ]}
          />
          <div class="pl-4 pt-4">
            <ButtonToggleAlt
              title={'Two columns'}
              isDisabled={$globalOptions.presMode === 'un_exo_par_page' ||
                $globalOptions.presMode === 'une_question_par_page'}
              bind:value={$globalOptions.twoColumns}
              id={'config-eleve-nb-colonnes-toggle'}
              explanations={['Two column', 'One column']}
            />
          </div>
        </div>
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-coopmaths-struct-light dark:text-coopmathsdark-struct-light"
          >
            Interactivity
          </div>
          <FormRadio
            title="Interactive"
            bind:valueSelected={$globalOptions.setInteractive}
            labelsValues={[
              { label: 'As imported', value: '2' },
              { label: 'All interactive', value: '1' },
              { label: "None interactive", value: '0' }
            ]}
          />
          <div class="pl-2 pt-4">
            <ButtonToggleAlt
              title={"Modify interactivity"}
              isDisabled={$globalOptions.setInteractive === '0'}
              bind:value={$globalOptions.isInteractiveFree}
              id={'config-eleve-interactif-permis-toggle'}
              explanations={["Students can make the exercise interactive or not.", "Students will not be able to change the interactivity status."]}
            />
          </div>
          <div class="pl-2 pt-2">
            <ButtonToggleAlt
              title={'Only one answer'}
              isDisabled={$globalOptions.setInteractive === '0'}
              bind:value={$globalOptions.oneShot}
              id={'config-eleve-refaire-toggle'}
              explanations={["Students will only have one opportunity to answer the exercises.", "Students will be able to redo the exercises as many times as they wish."]}
              on:toggle={handleSeed}
            />
          </div>
        </div>
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-coopmaths-struct-light dark:text-coopmathsdark-struct-light"
          >
            Data
          </div>
          <div class="flex flex-row justify-start items-center px-4">
            <ButtonToggleAlt
              title={'Different data'}
              bind:value={isDataRandom}
              id={'config-eleve-donnes-differentes-toggle'}
              explanations={["Each student will have pages with different data from another student.", 'All students will have identical pages.']}
              on:toggle={handleSeed}
            />
          </div>
<div
            class="pl-2 pb-2 font-bold text-coopmaths-struct-light dark:text-coopmathsdark-struct-light"
          >
            Clavier
          </div>
          <div class="flex flex-row justify-start items-center px-4">
            <ButtonToggleAlt
              title={'Clavier expérimental'}
              bind:value={isBetaKeyboard}
              id={'config-eleve-clavier-experimental'}
              explanations={['Nouveau clavier en test.', 'On reste sur l\'ancien clavier.']}
              on:toggle={handleKeyboard}
            />
          </div>
        </div>
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-coopmaths-struct-light dark:text-coopmathsdark-struct-light"
          >
            Correction
          </div>
          <div class="flex flex-row justify-start items-center px-4">
            <ButtonToggleAlt
              title={'Access to corrections'}
              bind:value={$globalOptions.isSolutionAccessible}
              id={'config-eleve-acces-corrections-toggle'}
              explanations={['Students will be able to access the corrections by clicking a button.', 'Students will have no way to see the correction.']}
            />
          </div>
        </div>
      </div>
      <div class="pt-4 pb-8 px-4">
        <Button
          on:click={handleEleveVueSetUp}
          class="px-2 py-1 rounded-md"
          title="View worksheet"
        />
      </div>
      <div class="flex flex-row justify-start px-4 py-2">
        <h3
          class="font-bold text-2xl text-coopmaths-struct dark:text-coopmathsdark-struct"
        >
          Utilisation
        </h3>
      </div>
      <div
        class="flex flex-col md:flex-row justify-start space-x-10 items-start md:items-center px-4"
      >
        <div
          class="text-coopmaths-struct-light dark:text-coopmathsdark-struct-light font-semibold"
        >
          URL format
        </div>
        <div class="flex">
          <FormRadio
            title="linkFormat"
            bind:valueSelected={currentLinkFormat}
            labelsValues={[
              { label: 'Plain', value: 'clear' },
              { label: 'Encrypted', value: 'crypt' },
              { label: 'Clipboard', value: 'short', isDisabled: true }
            ]}
            orientation="row"
          />
        </div>
      </div>
      <div class="flex flex-row justify-start items-start space-x-10 pt-3 pl-4">
        <div class="flex flex-col items-center px-2">
          <div
            class="text-coopmaths-struct-lightest dark:text-coopmathsdark-struct-light font-semibold"
          >
            Link
          </div>
          <div class="my-1">
            <ModalActionWithDialog
              on:display={() =>
                copyLinkToClipboard(
                  'linkCopiedDialog',
                  buildUrlAddendumForEsParam(),
                  availableLinkFormats[currentLinkFormat].isShort,
                  availableLinkFormats[currentLinkFormat].isEncrypted
                )}
              message="Link copied to clipboard!"
              messageError="Unable to create link in clipboard!"
              dialogId="linkCopiedDialog"
              tooltipMessage={availableLinkFormats[currentLinkFormat].toolTipsMessage}
              buttonSecondIcon={availableLinkFormats[currentLinkFormat].icon}
            />
          </div>
        </div>
        <div class="flex flex-col justify-center items-center px-2">
          <div
            class="text-coopmaths-struct-lightest dark:text-coopmathsdark-struct-lightest font-semibold"
          >
            QR-Code
          </div>
          <div class="my-1">
            <ModalForQRCode
              tooltipMessage={'QR-code (' + availableLinkFormats[currentLinkFormat].toolTipsMessage +
                ')'}
              width={QRCodeWidth}
              format={formatQRCodeIndex}
              isEncrypted={availableLinkFormats[currentLinkFormat].isEncrypted}
              isShort={availableLinkFormats[currentLinkFormat].isShort}
              urlAddendum={buildUrlAddendumForEsParam()}
              buttonSecondIcon={availableLinkFormats[currentLinkFormat].icon}
            />
          </div>
        </div>
        <div class="flex flex-col justify-center items-center px-2">
          <div
            class="text-coopmaths-struct-lightest dark:text-coopmathsdark-struct-light font-semibold"
          >
            Embed
          </div>
          <div class="my-1">
            <ModalActionWithDialog
              on:display={() =>
                copyEmbeddedCodeToClipboard(
                  'embeddedCodeCopiedDialog',
                  buildUrlAddendumForEsParam(),
                  availableLinkFormats[currentLinkFormat].isShort,
                  availableLinkFormats[currentLinkFormat].isEncrypted
                )}
              message="Link copied to clipboard!"
              messageError="Unable to create link in clipboard!"
              dialogId="embeddedCodeCopiedDialog"
              tooltipMessage={'Code (' +
                availableLinkFormats[currentLinkFormat].toolTipsMessage +
                ')'}
              buttonIcon={'bx-code-alt'}
              buttonSecondIcon={availableLinkFormats[currentLinkFormat].icon}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</main>
