import { specialKeys } from '../layouts/keysBlocks'
import type { keys } from '../lib/keycaps'
import { GAP_BETWEEN_KEYS, KEYCAP_WIDTH } from '../lib/sizes'
export type Keys = keyof typeof keys
export type KeysList = Keys[]
export type AlphanumericPages = 'AlphaUp' | 'AlphaLow' | 'Numeric'
export type BlockForKeyboard =
  | 'alphanumeric'
  | 'numbers'
  | 'numbersOperations'
  | 'variables'
  | 'basicOperations'
  | 'fullOperations'
  | 'hms'
  | 'greek'
  | 'trigo'
  | 'advanced'
  | 'lengths'
  | 'areas'
  | 'volumes'
  | 'capacities'
  | 'masses'

export interface CompleteKeysList {
  inline: KeysList
  block: KeysList
}

export interface KeyboardBlock {
  keycaps: CompleteKeysList
  cols: number
  title: string
  isUnits: boolean
}

export class Keyboard {
  blocks: KeyboardBlock[] = [specialKeys]

  constructor (kb?: KeyboardBlock) {
    if (kb) {
      this.blocks.push(kb)
    }
  }

  /**
   * Ajoute un bloc de touche au clavier
   * @param kb {KeyboardBlock} bloc de touches à ajouter
   * @returns le clavier lui-même (on peut donc chaîner cette fonction)
   */
  add = (kb: KeyboardBlock): Keyboard => {
    this.blocks.push(kb)
    return this
  }

  empty = (): Keyboard => {
    this.blocks.length = 0
    this.blocks.push(specialKeys)
  }

  /**
   * Retrouve le nombre de blocs dans le clavier
   * @returns nombre de blocs dans le clavier
   */
  numberOfBlocks = (): number => this.blocks.length

  /**
   * Construit un tableau dont les éléments sont le nombre de touche par bloc
   * @returns nombre de touches par bloc (liste de nombres)
   */
  numberOfKeysPerBlock = (): number[] => {
    const result: number[] = []
    for (const block of this.blocks) {
      result.push(block.keycaps.inline.length)
    }
    return result
  }

  /**
   * Calcule le nombre total de touches dans le clavier
   * @returns nombre total de touches
   */
  numberOfKeys = (): number =>
    this.numberOfKeysPerBlock().reduce((prev, current) => prev + current)
}

export const inLineBlockWidth = (
  block: KeyboardBlock,
  mode: 'sm' | 'md'
): number => {
  // 3G30-1 renvoie un keycaps undefined, pourquoi ?
  const numberOfKeys = block?.keycaps?.inline?.length || 0
  // console.log('nb of keys: ' + numberOfKeys + ' / key width: ' + KEYCAP_WIDTH[mode]s + ' / gap between keys: ' + GAP_BETWEEN_KEYS[mode])
  return (
    numberOfKeys * KEYCAP_WIDTH[mode] +
    (numberOfKeys - 1) * GAP_BETWEEN_KEYS[mode]
  )
}
export const usualBlockWidth = (
  block: KeyboardBlock,
  mode: 'sm' | 'md'
): number => {
  const numberOfCols = block.cols
  return numberOfCols * KEYCAP_WIDTH[mode] + (numberOfCols - 1) * GAP_BETWEEN_KEYS[mode]
}
