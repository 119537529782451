export const KEYCAP_WIDTH = {
  sm: 30,
  md: 40
}
export const KEYCAP_HEIGHT = {
  sm: 40,
  md: 40
}
export const GAP_BETWEEN_BLOCKS = {
  sm: 10,
  md: 48
}
export const GAP_BETWEEN_KEYS = {
  sm: 4,
  md: 8
}

export const SM_BREAKPOINT = 768
