// Définit un clavier personnalisé cf https://cortexjs.io/mathlive/guides/virtual-keyboards/
export const CLAVIER_COLLEGE6EME = {
  label: 'Maths', // Label displayed in the Virtual Keyboard Switcher
  tooltip: 'Clavier mathématique', // Tooltip when hovering over the label
  rows: [
    [
      { class: 'separator w5' },
      { label: '7', key: '7' },
      { label: '8', key: '8' },
      { label: '9', key: '9' },
      { latex: '\\div' },
      { class: 'separator w5' },
      { label: '%', key: '%' },
      { label: '°', latex: '\\operatorname{°}' },
      { latex: '(' },
      { latex: ')' },
      { class: 'separator w5' }
    ],
    [
      { class: 'separator w5' },
      { label: '4', latex: '4' },
      { label: '5', key: '5' },
      { label: '6', key: '6' },
      { latex: '\\times' },
      { class: 'separator w10' },
      {
        class: 'small',
        latex: '[',
        insert: '$[$' /// Crochets
      },
      {
        class: 'small',
        latex: ']',
        insert: '$]$' /// Crochets
      },
      { label: ';', key: ';' },
      { class: 'separator w10' }
    ],
    [
      { class: 'separator w5' },
      { label: '1', key: '1' },
      { label: '2', key: '2' },
      { label: '3', key: '3' },
      { latex: '-' },
      { class: 'separator w15' },
      { label: 'oui', key: 'oui' },
      { label: 'non', key: 'non' },
      /* {
        class: 'w20',
        label: 'espace', // Espace
        insert: '\\,'
      }, */
      { class: 'separator w15' }
    ],
    [
      { label: '0', key: '0' },
      { latex: ',' },
      { latex: '\\pi' },
      { latex: '+' },
      { class: 'separator w5' },
      {
        class: 'action',
        label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
        command: ['performWithFeedback', 'moveToPreviousChar']
      },
      {
        class: 'action',
        label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
        command: ['performWithFeedback', 'moveToNextChar']
      },
      {
        class: 'action font-glyph',
        label: '&#x232b;',
        command: ['performWithFeedback', 'deleteBackward']
      },
      {
        class: 'action font-glyph',
        label: '&#10006;',
        command: ['toggleVirtualKeyboard', 'toggleVirtualKeyboard']
      }
    ]
  ]
}

export const raccourcis6eme = {
  D: { mode: 'math', value: 'd' },
  '*': { mode: 'math', value: '\\times' },
  '.': { mode: 'math', value: ',' },
  pi: { mode: 'math', value: '\\pi' },
  ang: { mode: 'math', value: '\\widehat{#@}' },
  frac: { mode: 'math', value: '\\frac{#@}{#1}' },
  '/': { mode: 'math', value: '\\frac{#@}{#1}' },
  ' ': { mode: 'math', value: '\\,' }
}
