// Définit un clavier personnalisé cf https://cortexjs.io/mathlive/guides/virtual-keyboards/
export const CLAVIER_COLLEGE = {
  label: 'Maths', // Label displayed in the Virtual Keyboard Switcher
  tooltip: 'Clavier mathématique', // Tooltip when hovering over the label
  rows: [
    [
      { latex: 'a' },
      { latex: 'x' },
      { class: 'separator w5' },
      { label: '7', key: '7' },
      { label: '8', key: '8' },
      { label: '9', key: '9' },
      { latex: '\\div' },
      { class: 'separator w5' },
      {
        class: 'small',
        latex: '\\sqrt{#0}',
        insert: '$$\\sqrt{#0}$$'
      },
      /* Proposition Daniel Caillibaud en lieu et place de la puissance 2 actuelle
        {
          class: 'tex small',
          latex: 'x^2', // Puissance 2
          insert: '#@^{2}'
        },
        */
      {
        class: 'tex small',
        label: '<span><i>x</i>&thinsp;²</span>', // Puissance 2
        insert: '$$#@^{2}$$'
      },
      /* {
        class: 'tex small',
        label: '<span><i>x</i><sup>&thinsp;<i>3</i></sup></span>', // Puissance 3
        insert: '$$#@^{3}$$'
      }, */
      {
        class: 'tex small',
        latex: 'x^{#0}', // Puissance n
        insert: '$$#@^{#0}$$'
      },
      {
        class: 'small',
        latex: '\\times10^{#0}',
        insert: '$$\\times10^#0$$'
      }
    ],
    [
      { class: 'tex', latex: 'b' },
      { class: 'tex', latex: 'y' },
      { class: 'separator w5' },
      { label: '4', latex: '4' },
      { label: '5', key: '5' },
      { label: '6', key: '6' },
      { latex: '\\times' },
      { class: 'separator w5' },
      { class: 'small', latex: '\\frac{#0}{#0}' },
      { label: '=', key: '=' },
      // { latex: 'f' },
      { label: '%', key: '%' },
      {
        class: 'small',
        latex: '\\widehat{#0}',
        insert: '$$\\widehat{#0}$$' /// Angle
      }//,
      // { class: 'separator w10' }
    ],
    [
      { class: 'tex', label: 'c' },
      { class: 'tex', label: 'z' },
      { class: 'separator w5' },
      { label: '1', key: '1' },
      { label: '2', key: '2' },
      { label: '3', key: '3' },
      { latex: '-' },
      { class: 'separator w5' },
      { label: ';', key: ';' },
      { label: 'oui', key: 'oui' },
      { label: 'non', key: 'non' },
      // { label: '%', key: '%' },
      { label: '°', latex: '\\operatorname{°}' }
    ],
    [
      { latex: '\\lparen' }, // Parenthèses à coder ainsi sinon elles disparaissent lors de certains calculs
      { latex: '\\rparen' },

      { class: 'separator w5' },
      { label: '0', key: '0' },
      { latex: ',' },
      { latex: '\\pi' },
      { latex: '+' },
      { class: 'separator w5' },
      {
        class: 'action',
        label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
        command: ['performWithFeedback', 'moveToPreviousChar']
      },
      {
        class: 'action',
        label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
        command: ['performWithFeedback', 'moveToNextChar']
      },
      {
        class: 'action font-glyph',
        label: '&#x232b;',
        command: ['performWithFeedback', 'deleteBackward']
      },
      {
        class: 'action font-glyph',
        label: '&#10006;',
        command: ['toggleVirtualKeyboard', 'toggleVirtualKeyboard']
      }//,
      // { class: 'separator w10' }
    ]
  ]
}

export const raccourcisCollege = {
  D: { mode: 'math', value: 'd' },
  '*': { mode: 'math', value: '\\times' },
  '.': { mode: 'math', value: ',' },
  '%': { mode: 'math', value: '\\%' },
  '²': { mode: 'math', value: '^2' },
  '³': { mode: 'math', value: '^3' },
  pi: { mode: 'math', value: '\\pi' },
  ang: { mode: 'math', value: '\\widehat{#@}' },
  rac: { mode: 'math', value: '\\sqrt{#@}' },
  frac: { mode: 'math', value: '\\frac{#@}{#1}' },
  '/': { mode: 'math', value: '\\frac{#@}{#1}' },
  '<': '<',
  '>': '>',
  '>=': '\\geq',
  '<=': '\\leq',
  '(': '\\lparen',
  ')': '\\rparen'
}
